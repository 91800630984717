import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DataService } from '../data.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedProvider } from '../shared/shared';
import { Router, ActivatedRoute } from '@angular/router';
import { AddSpaceService } from '../appservices/addspace.service';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material';
import { LoaderService } from '../shared/loader';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @Output() spaceTypeSelected = new EventEmitter<Event>();
  homeScreenCreate : FormGroup;
  homeScreenEdit : FormGroup;
  spaces : string[] = ["Café", "Restaurant", "Restobar", "Meeting Space", "Event Venue", "Shared Office", "Coworking Space", "Library", "Studio", "Something else" ];
  selectedSpace: string = '';
  bookings:'';
  currdate = new Date();
  create : Boolean = true;
  constructor(private data : DataService, private fb:FormBuilder, public shared : SharedProvider, 
    private router : Router, private addspace : AddSpaceService, private dialog : MatDialog,
		private loader: LoaderService, private route : ActivatedRoute) { }

  ngOnInit() {
    this.data.currentSpace.subscribe(space => this.selectedSpace = space );
    this.homeScreenCreate = this.fb.group({
      typeSelected:['',Validators.required]
    });
    this.homeScreenEdit = this.fb.group({
      appId : ['', Validators.required],
      emailId : ['', Validators.required]
    });
    this.currdate.setHours(0, 0, 0, 0);    
    let err = this.shared.getErrorString();
    if(err)
    {
       const dialogRef = this.dialog.open(DialogComponent, {
          width: '500px',
         data: { message: err, action : "Error" }
       });  
       dialogRef.afterClosed().subscribe(result => { 
        this.shared.setErrorString('');
      });
    }
  }

  selectChangeHandler () {
    if(this.create)
    {
      this.data.changeSpace(this.selectedSpace);
      this.data.setAppId('');
      this.shared.setSpaceData('');
    }
    else
    {
      let appid = this.homeScreenEdit.value.appId;
      let email = this.homeScreenEdit.value.emailId;
      let data = {
        applicationId : appid,
        emailId : email
      }
      this.loader.display(true);
      this.addspace.getSpaceDataById(data).then((res : any) => {
        this.loader.display(false);
          this.data.changeSpace(res[0].type);
          this.shared.setSpaceData(res[0]);
          this.router.navigate(['/edit', appid]);
      })
      .catch(err => {
        this.loader.display(false);
        let errText;
        if(err.status == 300)
        {
          errText = "Invalid Email Id";
        }
        else if(err.status == 301)
        {
          errText = "No data found"
        }
        else if(err.status == 302)
        {
          errText = "Invalid Application Id"
        }else {
          errText = "Something went wrong"
        }
        this.openDialog(errText);
      });
      

    }    
  }

  openDialog(errorText : string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
       width: '500px',
      data: { message: errorText, action : "Error" }
    });
  }


}
