import { EventEmitter } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable()
export class SharedProvider {
public applicationData : any;
public err : string;
public url : any[];
public citiesMetaData : any;
private activeCities : any = [];
constructor(private db:AngularFireDatabase)
{}

setSpaceData(data) {
    this.applicationData = data;
  }
getSpaceData() {
    return this.applicationData;
  }
getErrorString()
{
  return this.err;
}
setErrorString(str)
{
  this.err = str;
}

getImageLink()
{
  return this.url;
}

setImageLink(link)
{
  this.url = link;
}

setOperationalCities(data)
{
  this.citiesMetaData = data;
}

getActiveCities()
{
  if(this.citiesMetaData)
  {
    this.activeCities = [];
    Object.keys(this.citiesMetaData).forEach((key, index) => {
      let data = this.citiesMetaData[key];
      data.forEach(city => {
        if(city.available)
        this.activeCities.push({
          name:city.name,
          value:city.value,
          cityId:city.cityId
        });
      });       
    });
    this.activeCities.sort(function(a,b) {
      return a.cityId - b.cityId
    });
    return this.activeCities;
  }
}


}
