import { Component,  ViewChild, ChangeDetectorRef , AfterViewInit} from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { MatDialog} from '@angular/material/dialog';
import { BasicCheckScreenComponent } from '../basic-check-screen/basic-check-screen.component';
import { PeopleInfoComponent } from '../people-info/people-info.component';
import { SpaceInfoComponent } from '../space-info/space-info.component';
import { FareDetailsComponent } from '../fare-details/fare-details.component';
import { AmenitiesComponent } from '../amenities/amenities.component';
import { CompanyInfoComponent } from '../company-info/company-info.component';
import { AgreementComponent } from '../agreement/agreement.component';
import { DataService } from '../data.service';
import { SpaceTypeComponent } from '../space-type/space-type.component';
import { SharedProvider } from '../shared/shared';
import { ActivatedRoute } from '@angular/router';
import { AddSpaceService } from '../appservices/addspace.service';
import { LoaderService } from '../shared/loader';
import { Router } from '@angular/router';

export interface DialogData {
  title: string;
  alert: string;
}

@Component({
  selector: 'app-add-space',
  templateUrl: './add-space.component.html',
  styleUrls: ['./add-space.component.scss']
})
export class AddSpaceComponent implements AfterViewInit {

// showSteps : boolean[] = [false, false, false, false, false, false];
// showSteps : boolean[] = [true, true, true, true, true, true];
selectedSpace : string = '';
applicationId : string = '';
spaceAdded : boolean = false;
show : boolean = false;
mode : string;
title : string;
text : string;
appstatus : boolean;
constructor(private cdr: ChangeDetectorRef, public dialog: MatDialog, private data: DataService, public shared : SharedProvider,
                        private route : ActivatedRoute, private addspace : AddSpaceService,
                        private router : Router, private loader: LoaderService) {
}
 
 @ViewChild('BasicCheckScreenComponent',{static : false}) basicCheckComponent : BasicCheckScreenComponent;
 @ViewChild('PeopleInfoComponent',{static: false}) peopleInfoComponent : PeopleInfoComponent;
 @ViewChild('SpaceInfoComponent',{static: false}) spaceInfoComponent : SpaceInfoComponent;
 @ViewChild('FareDetailsComponent',{static : false}) fareDetailsComponent : FareDetailsComponent;
 @ViewChild('AmenitiesComponent',{static : false}) amenitiesComponent : AmenitiesComponent;
 @ViewChild('CompanyInfoComponent',{static : false}) companyInfoComponent : CompanyInfoComponent;
 @ViewChild('AgreementComponent',{static : false}) agreementComponent : AgreementComponent;
 @ViewChild('SpaceTypeComponent',{static : false}) spaceTypeComponent : SpaceTypeComponent;
 

  get basicStep() {
    return this.basicCheckComponent ? this.basicCheckComponent.basicStep : null;
  }
  get peopleInfoStep() {
   return this.peopleInfoComponent ? this.peopleInfoComponent.peopleInfoStep : null;
  }
  get spaceInfoStep() {
  return this.spaceInfoComponent ? this.spaceInfoComponent.spaceInfoStep : null;
  }
  get fareDetailsOthers() {
    return this.fareDetailsComponent ? this.fareDetailsComponent.fareDetailsOthers : null;
  }
  get fareDetailsConf() {
    return this.fareDetailsComponent ? this.fareDetailsComponent.fareDetailsConf : null;
  }

  get spaceType(){
    return this.spaceTypeComponent ? this.spaceTypeComponent.spaceType : null;
  }

  get amenitiesStep() {
    return this.amenitiesComponent ? this.amenitiesComponent.amenitiesStep : null;
  }
  get companyInfoStep() {
    return this.companyInfoComponent ? this.companyInfoComponent.companyInfoStep : null;
  }
  get agreementStep() {
    return this.agreementComponent ? this.agreementComponent.agreementStep : null;
  }


  

  eventHandler($event: boolean) {
    this.spaceAdded = $event;
  }
  
ngOnInit()
{
  this.data.currentSpace.subscribe( space => this.selectedSpace = space );
  if(this.selectedSpace == '')
  {
    this.router.navigate(['/']);        
    this.shared.setErrorString('Missing Space Type'); 
  }
  if(this.selectedSpace != 'MeetingSpace' && this.selectedSpace != 'EventVenue' && this.selectedSpace != 'SharedOffice' && this.selectedSpace != 'TrainingRoom')
  {
    this.title = 'Space Photos';
    this.text = 'Awesome ! Now add attractive photos of your space to proceed further.'
  }
  else
  {
    this.title = 'Subspace Details';
    this.text = 'Awesome !  Now let us get to the details of the space. We will create a listing in our platform for each space type you add below.'
  }
  this.data.appId.subscribe( id => this.applicationId = id );
  this.route.paramMap.subscribe( params => {
  const appid = params.get('appid');
    if(appid)
    {
      this.mode = 'edit';
      this.applicationId = appid;
      let data = this.shared.getSpaceData();
      if(data == undefined)
      {
        this.router.navigate(['/']);        
        this.shared.setErrorString('Missing Email Id');
      }
      else 
      {
        if(data.status == 'Submitted')
        {
          this.appstatus = true;
        }
        else
        {
          this.appstatus = false;
        }
      }
    }
  })
}



  ngAfterViewInit() {     
  }
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
}
