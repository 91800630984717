/// <reference types="@types/googlemaps" />
import { Component, ViewChild, EventEmitter, Output, AfterViewInit, Input } from '@angular/core';
// import { LoaderService } from '../sharedcomponents/index';
// import { } from 'googlemaps';

@Component({
    selector: 'AutoCompleteComponent',
    template: `
      <input
        class="form-control"
        type="text"
        #addresstext
        >
    `,
})
export class AutocompleteComponent implements AfterViewInit {
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @ViewChild('addresstext',{static : false}) addresstext: any;
    @Input() countries: string[];
    address: google.maps.places.PlaceResult;

    constructor() { }

    ngAfterViewInit() {
        this.getPlaceAutocomplete();
    }

    private getLattitue() {
        return this.address.geometry.location.lat();
    }

    private getLangitude() {
        return this.address.geometry.location.lng();
    }


    private isLocality(addComponent: google.maps.GeocoderAddressComponent): boolean {
        const { types } = addComponent;
        return types.includes('sublocality');
    }

    private isStreet(addComponent: google.maps.GeocoderAddressComponent): boolean {
        const { types } = addComponent;
        return types.includes('street_number') || types.includes('route');
    }

    private isCity(addComponent: google.maps.GeocoderAddressComponent): boolean {
        return addComponent.types.includes('locality');
    }

    private isPinCode(addComponent: google.maps.GeocoderAddressComponent): boolean {
        return addComponent.types.includes('postal_code');
    }

    private isCountry(addComponent: google.maps.GeocoderAddressComponent): boolean {
        return addComponent.types.includes('country');
    }

    private getDetailedAddress() {
        const address = {};
        address['location'] = [this.getLattitue(), this.getLangitude()];
        this.address.address_components.forEach(addressComponent => {
            const name = addressComponent.long_name;
            if (this.isPinCode(addressComponent)) {
                address['zipcode'] = name;
            } else if (this.isCountry(addressComponent)) {
                address['country'] = name;
            } else if (this.isCity(addressComponent)) {
                address['city'] = name;
            } else if (this.isLocality(addressComponent)) {
                address['locality'] = name;
            } else if (this.isStreet(addressComponent)) {
                address['street'] = name;
            }
        });
        return address;
    }


    private getPlaceAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(
            this.addresstext.nativeElement,
            {
                componentRestrictions: { country: this.countries },
                types: ['establishment', 'geocode']
            });
        $(this.addresstext.nativeElement).on('change', () => {
            // this.loader.display(true);
            /**
                The following setTimeout with 5 seconds is to stop the loading screen when
                there is no response from Google Map Place AutoComplete API.
             */
            setTimeout(() => {
                // this.loader.display(false);
            }, 5000);
        });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            // this.loader.display(false);
            this.address = autocomplete.getPlace();
            if (this.address.geometry === undefined || this.address.geometry === null) {
                this.setAddress.emit({});
                return;
            }
            this.setAddress.emit(this.getDetailedAddress());
        });
    }
}
