import { Directive, Input, OnInit } from '@angular/core';

/**
 * Generated class for the ImgPreloadDirective directive.
 *
 * See https://angular.io/api/core/Directive for more info on Angular
 * Directives.
 */
@Directive({
  selector: '[img-preloader]', // Attribute selector
  host: {
    '[attr.src]': 'finalImage'    //the attribute of the host element we want to update. in this case, <img 'src' />
  }
})
export class ImgPreloadDirective implements OnInit{
  @Input('img-preloader') targetSource: string;
  
  downloadingImage : any; // In class holder of remote image
  
  finalImage: any; //property bound to our host attribute.
  
  // Set an input so the directive can set a default image.
  @Input() defaultImage : string = 'assets/img/preloader.png';
  constructor() {
  }

  ngOnInit() {
    //First set the final image to some default image while we prepare our preloader:
    this.finalImage = this.defaultImage;
 
 
    this.downloadingImage = new Image();  // create image object
    this.downloadingImage.onload = () => { //Once image is completed, console.log confirmation and switch our host attribute
      this.finalImage = this.targetSource;  //do the switch 😀
    }
    this.downloadingImage.src = this.targetSource;
  }

}
