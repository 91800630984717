import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FareDetailsComponent } from '../fare-details/fare-details.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  message : string;
  action:string;
  key:string;
  appstatus : boolean;
  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) data) { 
    this.message = data.message;
    this.action = data.action;
    this.key = data.key;
    this.appstatus = data.appstatus;
  }

  ngOnInit() {      
  }


  close()
  {
    this.dialogRef.close();
  }

}
