import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoaderService } from '../shared/loader';
import { Router } from '@angular/router';
import { SharedProvider } from '../shared/shared';
import { AddSpaceService } from '../appservices/addspace.service';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent implements OnInit {
  agreementStep : FormGroup;
  constructor(private formBuilder : FormBuilder,private loader: LoaderService,private router : Router,
     private shared : SharedProvider, private addspaceService : AddSpaceService, private dialog : MatDialog) { }
  // agreementText : string[] = ["Customer will pay a security deposit equivalent to 1  month rent",
  //                         "Customer will be invoiced by GoFloaters at the time of booking and start of every month",
  //                         "GoFloaters will collect the payment from the customer",
  //                         "You will have to submit an invoice to GoFloaters at the end of every month",
  //                         "GoFloaters will pay the invoice by 5th of the following month to you after deducting its revenue share of 15%",
  //                         "Customer will provide one month notice (with the booking is for 2 months or more) to you",
  //                         "Final month's rent will be adjusted against the security deposit"
  //                        ];
  agreementText : string;
  disableAgreeBtn : boolean = true;
  disableDeclineBtn : boolean = false;
  @Input() appstatus : boolean;
  @Input() mode : string;

  ngOnInit() {
    // this.agreementStep = this.formBuilder.group({
    //   securityDeposit : ['Agree', Validators.required],
    //   bookingInvoice : ['Agree', Validators.required],
    //   payment : ['Agree', Validators.required],
    //   monthlyInvoice : ['Agree', Validators.required],
    //   revenueShare : ['Agree', Validators.required],
    //   notice : ['Agree', Validators.required],
    //   finalRent : ['Agree', Validators.required]
    // });
    if(this.appstatus)
    {
      this.agreementText = "We have already received your space listing application. We'll review the same and send you a confirmation mail shortly.";
    }
    else
    {
      this.agreementText = "Submit your space listing application for aprroval. We'll review the same and send you a confirmation mail shortly.";
    }
  }

  toggleAddSpaceBtn($event)
  {
    if($event.checked)
    {
      this.disableAgreeBtn = false;
      this.disableDeclineBtn = true;
    }
    else
    {
      this.disableAgreeBtn = true;
      this.disableDeclineBtn = false;
    }

  }

  changeStatus()
  {
    let data = this.shared.getSpaceData();
    let params;
    params = {
      applicationId : data.applicationId,
      applicationKey : data.applicationKey
    }
    
    this.loader.display(true);
    return new Promise((resolve, reject) => {
      this.addspaceService.changeStatus(params).then((res : any) => {
        this.loader.display(false);
        if(res.msg == 'success')
        {
          const dialogRef = this.dialog.open(DialogComponent, {
            width: '500px',
           data: { message: 'Application submitted', action : "Error" }
         });  
         dialogRef.afterClosed().subscribe(result => { 
          this.router.navigate(['/']);
        });
      }
      })
        .catch(err => {
          this.loader.display(false);
          if (err.status === 403) {
            console.log(err);
          }
        });
      })
  }

}
