import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from '../material.module';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { AddSpaceComponent } from './add-space/add-space.component';
import { BasicCheckScreenComponent } from './basic-check-screen/basic-check-screen.component';
import { PeopleInfoComponent } from './people-info/people-info.component';
import { SpaceInfoComponent } from './space-info/space-info.component';
import { FareDetailsComponent } from './fare-details/fare-details.component';
import { AmenitiesComponent } from './amenities/amenities.component';
import { CompanyInfoComponent } from './company-info/company-info.component';
import { AgreementComponent } from './agreement/agreement.component';
import { DialogComponent } from './dialog/dialog.component';
import { SpaceTypeComponent } from './space-type/space-type.component';
import { DragulaModule } from "ng2-dragula";
import { AppConstants } from "./app.constants";
import * as $ from 'jquery';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AutocompleteComponent } from "./google-service/google-places.component";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule  } from 'angularfire2/auth';
import { HttpClientModule } from '@angular/common/http'; 
import { HttpModule } from '@angular/http';
import { AddSpaceService } from './appservices/addspace.service';
import { SharedProvider } from './shared/shared';
import { DropZoneDirective } from './drop-zone.directive';
import { ImgPreloadDirective } from "./img.preload";
import { SpinnerComponent } from "./shared/spinner/spinner.component";
import { LoaderService } from "./shared/loader";
import { CanDeactivateGuard } from './can-deactivate.guard';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    AddSpaceComponent,
    BasicCheckScreenComponent,
    PeopleInfoComponent,
    SpaceInfoComponent,
    FareDetailsComponent,
    AmenitiesComponent,
    CompanyInfoComponent,
    AgreementComponent,
    DialogComponent,
    SpaceTypeComponent, 
    AutocompleteComponent, 
    ImgPreloadDirective,
    DropZoneDirective,
    SpinnerComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    DragulaModule.forRoot(),
    MaterialModule,
    AngularFireModule.initializeApp(AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].firebase),
    AngularFireStorageModule,
    AngularFirestoreModule,
    GooglePlaceModule,
    HttpClientModule,
    HttpModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    ImageCropperModule
  ],
  entryComponents: [DialogComponent],
  providers: [
    DragulaModule,
    AddSpaceService,
    SharedProvider,
    LoaderService,
    CanDeactivateGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
