import {Injectable} from '@angular/core';

@Injectable()
export class AppConstants {
  public static  CONSTANTS = {
    currentEnvironment: "prod",
    dev: {
      firebase: {
        apiKey: "AIzaSyDYYQUOaQ08YOogOvnhccMPHesAIlFNShI",
        authDomain: "gofloatersdev.firebaseapp.com",
        databaseURL: "https://gofloatersdev.firebaseio.com",
        projectId: "gofloatersdev",
        storageBucket: "gofloatersdev.appspot.com",
        messagingSenderId: "509881371385"
      },
      baseurl: 'https://us-central1-gofloatersdev.cloudfunctions.net/api/admin',
      appurl: 'https://us-central1-gofloatersdev.cloudfunctions.net/api',
      loginurl: 'https://us-central1-gofloatersdev.cloudfunctions.net/api/login',
      razorPayKey: 'rzp_test_87k0WW6Nk4NGoC'
    },
    local: {
      firebase: {
        apiKey: "AIzaSyDYYQUOaQ08YOogOvnhccMPHesAIlFNShI",
        authDomain: "gofloatersdev.firebaseapp.com",
        databaseURL: "https://gofloatersdev.firebaseio.com",
        projectId: "gofloatersdev",
        storageBucket: "gofloatersdev.appspot.com",
        messagingSenderId: "509881371385"
      },
      baseurl: 'http://localhost:5000/gofloatersdev/us-central1/api/admin',
      appurl: 'http://localhost:5000/gofloatersdev/us-central1/api',
      loginurl: 'http://localhost:5000/gofloatersdev/us-central1/api/login',
      razorPayKey: 'rzp_test_87k0WW6Nk4NGoC'
    },
    prod: {
      firebase: {
        apiKey: "AIzaSyD0UFJFOezqn0sk9gVnRUdJ0sMa1Yi8PZE",
        authDomain: "gofloaters.firebaseapp.com",
        databaseURL: "https://gofloaters.firebaseio.com",
        projectId: "gofloaters",
        storageBucket: "gofloaters.appspot.com",
        messagingSenderId: "187167465849",
        appId: "1:187167465849:web:425043bfad6fcb46910fa8"
      },
      baseurl: 'https://us-central1-gofloaters.cloudfunctions.net/api/admin',
      appurl: 'https://us-central1-gofloaters.cloudfunctions.net/api',
      loginurl: 'https://us-central1-gofloaters.cloudfunctions.net/api/login',
      razorPayKey: 'rzp_live_L4jnEFvzps4krf'
    },
    spaceRocket:{
      getSpaceDataById: '/spacerocket/getspacedatabyid',
      addSpace : '/spacerocket/addspace',
      addphotos: '/spacerocket/addOrRemovePhotos',
      subSpace: '/spacerocket/addOrRemoveSubSpace',
      changeStatus : '/spacerocket/changeStatus',
      getmetadata:'/getmetadata'
    },
    filestorage: {
      filepath: 'images'
    }
  }
}
