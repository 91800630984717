import { Component, OnInit, Output, EventEmitter, Input, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { AddSpaceService } from '../appservices/addspace.service';
import { DataService } from '../data.service';
import { SharedProvider } from '../shared/shared';
import * as _ from "lodash";
import { LoaderService } from '../shared/loader';
import { FormCanDeactivate } from '../form-can-deactivate';

@Component({
  selector: 'app-basic-check-screen',
  templateUrl: './basic-check-screen.component.html',
  styleUrls: ['./basic-check-screen.component.scss']
})
export class BasicCheckScreenComponent extends FormCanDeactivate implements OnInit {
  @Input() mode : string;
  @Input() appstatus : boolean;
  basicStep : FormGroup;
  errorMsg: string;
  hide : boolean = false;
  selectedSpace :string;
  spaceData;
  cityInfo:any;

  constructor(private formBuilder : FormBuilder,private data: DataService, public addspaceService : AddSpaceService, 
    public shared : SharedProvider, private loader: LoaderService ) {
    super();
  }
    
    @ViewChild('form',{static:false})
    form: NgForm;

  ngOnInit() {
    this.data.currentSpace.subscribe(space => this.selectedSpace = space );    
    if(this.shared.citiesMetaData)
      this.cityInfo = this.shared.getActiveCities();
    else
    {
      this.loader.display(true);
      this.addspaceService.getMetaData().then(val => {
      this.loader.display(false);
      this.cityInfo = this.shared.getActiveCities();
    }).catch(err => {
      this.loader.display(false);
      console.log(err);
    });
    }
    this.basicStep = this.formBuilder.group({
      city: ['', [Validators.required]],
      wifi: ['', [Validators.required]],
      restroom : ['', [Validators.required]],
      ac : ['', [Validators.required]],
      check : [null]
    });
    this.formControlValueChanged();
    if(this.mode == 'edit')
    {
      let savedData = this.shared.getSpaceData();
      let data = savedData.spaceData.basicDetails;
      this.basicStep.patchValue({
        city : data.city,
        wifi : data.wifi,
        ac: data.ac,
        restroom : data.restroom
      });  
    }
    if(this.appstatus)
    {
      this.basicStep.disable();
    }
  }

  formControlValueChanged() {
    const ch = this.basicStep.get('check');
    this.basicStep.valueChanges.subscribe(
      (mode: any) => {
      
    //   if((mode.city == 'Chennai' || mode.city == 'Hyderabad') && ( mode.ac == 'No' || mode.wifi == 'No' || mode.restroom == 'No' ))
    //   {
    //     this.errorMsg = "AC, Wifi and access to a clean rest room are mandatory to get listed in our platform. ";  
    //     ch.setErrors({
    //               invalid:true
    //             })
    //     this.hide = true;
        
    // }
    if ( mode.wifi == 'No' || mode.restroom == 'No' )
    {
      this.errorMsg = "WiFi and access to a clean rest room are mandatory to get listed in our platform. " ;
      ch.setErrors({
        invalid:true
      })
     this.hide = true;
    }
    else
    {
      ch.setErrors(null);
      this.hide = false;
    }
  });
}

ngAfterViewInit() {   

 
  
}


submitHandler()
 {  
  let data;
  let existingData = this.shared.getSpaceData();
  if(existingData != undefined && existingData.status == "InProgress")
  {
    existingData.spaceData.basicDetails = this.basicStep.value;
    this.loader.display(true);
    return new Promise((resolve, reject) => {
      this.addspaceService.addSpace(existingData).then((res : any) => {
        this.loader.display(false);
        this.shared.setSpaceData(res);
        resolve(res);
       })
        .catch(err => {
          this.loader.display(false);
          if (err.status === 403) {
            console.log(err);
          }
        });
      });
  }  
  else{
    data = {
      spaceData : {
        basicDetails : this.basicStep.value
      },
      status : "Initiated",
      type: this.selectedSpace
    }  
    this.loader.display(true);
    return new Promise((resolve, reject) => {
      this.addspaceService.addSpace(data).then((res : any) => {
        this.data.setAppId(res.applicationId);
        res.status = "InProgress";
        this.loader.display(false);
        this.shared.setSpaceData(res);
        resolve(res);
       })
        .catch(err => {
          this.loader.display(false);
          if (err.status === 403) {
            console.log(err);
          }
        });
      });
  }
}

}
