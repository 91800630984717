import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm} from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import * as $ from "jquery";
import { SharedProvider } from '../shared/shared';
import { AddSpaceService } from '../appservices/addspace.service';
import { LoaderService } from '../shared/loader';
import { FormCanDeactivate } from '../form-can-deactivate';
import { DataService } from '../data.service';

@Component({
  selector: 'app-space-info',
  templateUrl: './space-info.component.html',
  styleUrls: ['./space-info.component.scss']
})
export class SpaceInfoComponent extends FormCanDeactivate implements OnInit {
  @Input() mode : string;
  @Input() appstatus : boolean;
  selectedSpace :string;
  spaceInfoStep : FormGroup;
  space: any = { address: {}, location: [], photos: [] };
  weeklyFrom = { hour: 9, minute: 0 };
  weeklyTo = { hour: 18, minute: 0 };
  satFrom = { hour: 10, minute: 0 };
  satTo = { hour: 17, minute: 0 };
  sunFrom = { hour: 10, minute: 0 };
  sunTo = { hour: 17, minute: 0 };
  allDays = true;
  disableSat = false;
  disableSun = false;
  meridian = true;
  address = '';
  latitude : any;
  longitude : any;
  from : any = [
    { hour: 9, minute: 0 },{ hour: 9, minute: 0 },{ hour: 9, minute: 0 },{ hour: 9, minute: 0 },{ hour: 9, minute: 0 }
              ];
  to = [
    { hour: 18, minute: 0 },{ hour: 18, minute: 0 },{ hour: 18, minute: 0 },{ hour: 18, minute: 0 },{ hour: 18, minute: 0 }
       ]
 disablePicker = [ false, false, false, false, false ] ;
 operationTimings = {};
 private days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday"
];
  options = {
    componentRestrictions : {
      country : 'IN'
    }
  }

  constructor(private formBuilder : FormBuilder, public shared : SharedProvider,
     public addspaceService : AddSpaceService, private loader: LoaderService,private data: DataService,) {
    super();
  }
  
     @ViewChild('form',{static:false})
     form: NgForm;
     cityInfo:any;
  ngOnInit() {
    if(this.shared.citiesMetaData)
      this.cityInfo = this.shared.getActiveCities();
    else
    {
      this.loader.display(true);
      this.addspaceService.getMetaData().then(val => {
      this.loader.display(false);
      this.cityInfo = this.shared.getActiveCities();
    }).catch(err => {
      this.loader.display(false);
      console.log(err);
    });
    }
    this.data.currentSpace.subscribe(space => this.selectedSpace = space );
    this.spaceInfoStep = this.formBuilder.group({
          spaceName: ['', [Validators.required]],
          spaceDesc: ['', [Validators.required]],
          loc: ['',Validators.required],
          streetNo: ['', [Validators.required]],
          locality: ['', [Validators.required]],
          city:['', [Validators.required]],
          country:['India', [Validators.required]],
          pincode:['',[Validators.required]],
          landmark: ['', [Validators.required]],
          phone: ['', [Validators.required]],
          metro: ['', [Validators.required]],
          powerBackup: ['', [Validators.required]],
          wifiBackup: ['', [Validators.required]],
          smokeZone: ['', [Validators.required]],
          parking: ['', [Validators.required]],
          // weekCheck : ['',[Validators.required]]
    });

    if(this.mode == 'edit')
    {
      let savedData = this.shared.getSpaceData();
      let data = savedData.spaceData.spaceDetails;
      if(data)
      {      
      this.allDays = data.allDays
      this.spaceInfoStep.patchValue({
        spaceName: data.spaceName,
        spaceDesc: data.spaceDesc,
        loc: data.loc,
        streetNo: data.streetNo,
        locality: data.locality,
        city: data.city,
        country:data.country,
        pincode:data.pincode,
        landmark: data.landmark,
        phone: data.phone,
        metro: data.metro,
        powerBackup: data.powerBackup,
        wifiBackup: data.wifiBackup,
        smokeZone: data.smokeZone,
        parking: data.parking,
      });  
      this.setOperationTimings(data.operationTimings);
      this.latitude = data.latitude;
      this.longitude = data.longitude;
    }
    }
    if(this.appstatus)
    {
      this.spaceInfoStep.disable();
    }
    
  }

 

  @ViewChild("placesRef",{static : false}) placesRef : GooglePlaceDirective;
    
  handleAddressChange(place: any) {
    this.spaceInfoStep.controls['streetNo'].reset('');
    this.spaceInfoStep.controls['locality'].reset('');
    this.spaceInfoStep.controls['city'].reset('');
    this.spaceInfoStep.controls['pincode'].reset('');
		try {
      this.address = place.formatted_address;
      this.latitude = place.geometry.location.lat();
      this.longitude = place.geometry.location.lng();
      let addrComp = place.address_components;
      let size = place.address_components.length;
      for( let i = 0; i < size; i++)
      {
        switch(addrComp[i].types[0])
        {
          case "street_number" : this.spaceInfoStep.controls['streetNo'].setValue(addrComp[i].long_name);
                                 break;  
          case "sublocality_level_1" : this.spaceInfoStep.controls['locality'].setValue(addrComp[i].long_name);
                                       break;    
          case "locality" : if(addrComp[i].long_name.toLowerCase() == 'chennai' || addrComp[i].long_name.toLowerCase() == 'bangalore' || addrComp[i].long_name.toLowerCase() == 'bengaluru' || addrComp[i].long_name.toLowerCase() == 'hyderabad' || addrComp[i].long_name.toLowerCase() == 'coimbatore'
                              || addrComp[i].long_name.toLowerCase() == 'new delhi' || addrComp[i].long_name.toLowerCase() == 'noida' || addrComp[i].long_name.toLowerCase() == 'faridabad' || addrComp[i].long_name.toLowerCase() == 'gaziabad' || addrComp[i].long_name.toLowerCase() == 'pune' 
                              || addrComp[i].long_name.toLowerCase() == 'kochi' || addrComp[i].long_name.toLowerCase() == 'mumbai' || addrComp[i].long_name.toLowerCase() == 'gurugram' )
                            {
                              this.spaceInfoStep.controls['city'].setValue(addrComp[i].long_name);                                  
                            }   
                            break;  
          case "postal_code" : this.spaceInfoStep.controls['pincode'].setValue(addrComp[i].long_name);
                               break;         
        }
      }
		} catch (e) { }
  }
  
  resetHoliday(day:string){
    if(day == 'Saturday')
    {
      this.disableSat = !this.disableSat;
      this.satFrom = {hour:0, minute:0};
      this.satTo = {hour:23, minute:59};
    }
    else if (day == 'Sunday')
    {
      this.disableSun = !this.disableSun;
      this.sunFrom = {hour:0, minute:0};
      this.sunTo = {hour:23, minute:59};
    }
  }
  onChange(index:number)
  {
    this.disablePicker[index] = !this.disablePicker[index];
    this.from[index] = {hour:0, minute:0};
    this.to[index] = {hour:23, minute:59};
  }
  

  weekdays()
  {
    this.allDays = !this.allDays;
  }

  submitHandler()
  {
    let data = this.shared.getSpaceData();
    this.getOperationTimings();
    data.spaceData.spaceDetails = this.spaceInfoStep.value;  
    data.spaceData.spaceDetails.operationTimings = this.operationTimings;
    data.spaceData.spaceDetails.allDays = this.allDays;
    data.spaceData.spaceDetails.latitude = this.latitude;
    data.spaceData.spaceDetails.longitude = this.longitude;
    this.loader.display(true);
    return new Promise((resolve, reject) => {
      this.addspaceService.addSpace(data).then((res : any) => {
        this.loader.display(false);
        this.shared.setSpaceData(res);
        resolve(res);
       })
        .catch(err => {
          this.loader.display(false);
          if (err.status === 403) {
            console.log(err);
          }
        });
      })

  }

  getOperationTimings()
  {    
    if(this.allDays)
    {
      this.operationTimings = {
        monday : {
          from : this.weeklyFrom,
          to : this.weeklyTo,
          holiday : false
        },
        tuesday : {
          from : this.weeklyFrom,
          to : this.weeklyTo,
          holiday : false
        },
        wednesday : {
          from : this.weeklyFrom,
          to : this.weeklyTo,
          holiday : false
        },
        thursday : {
          from : this.weeklyFrom,
          to : this.weeklyTo,
          holiday : false
        },
        friday : {
          from : this.weeklyFrom,
          to : this.weeklyTo,
          holiday : false
        },
        saturday : {
          from : this.satFrom,
          to : this.satTo,
          holiday : this.disableSat
        },
        sunday : {
          from : this.sunFrom,
          to : this.sunTo,
          holiday : this.disableSun
        }
      }
    }
    else
    {
      this.operationTimings = {
        monday : {
          from : this.from[0],
          to : this.to[0],
          holiday : this.disablePicker[0]
        },
        tuesday : {
          from : this.from[1],
          to : this.to[1],
          holiday : this.disablePicker[1]
        },
        wednesday : {
          from : this.from[2],
          to : this.to[2],
          holiday : this.disablePicker[2]
        },
        thursday : {
          from : this.from[3],
          to : this.to[3],
          holiday : this.disablePicker[3]
        },
        friday : {
          from : this.from[4],
          to : this.to[4],
          holiday : this.disablePicker[4]
        },
        saturday : {
          from : this.satFrom,
          to : this.satTo,
          holiday : this.disableSat
        },
        sunday : {
          from : this.sunFrom,
          to : this.sunTo,
          holiday : this.disableSun
        }
      }
    }
  }

  setOperationTimings(timing : any)
  {
    if(this.allDays)
    {
      this.weeklyFrom.hour = timing.monday.from.hour;
      this.weeklyFrom.minute = timing.monday.from.minute;
      this.weeklyTo.hour = timing.monday.to.hour;
      this.weeklyTo.minute = timing.monday.to.minute;      
    }      
    else
    {      
      //Set timings for weekdays individually
      this.from[0].hour = timing.monday.from.hour;
      this.from[0].minute = timing.monday.from.minute;
      this.from[1].hour = timing.tuesday.from.hour;
      this.from[1].minute = timing.tuesday.from.minute;
      this.from[2].hour = timing.wednesday.from.hour;
      this.from[2].minute = timing.wednesday.from.minute;
      this.from[3].hour = timing.thursday.from.hour;
      this.from[3].minute = timing.thursday.from.minute;
      this.from[4].hour = timing.friday.from.hour;
      this.from[4].minute = timing.friday.from.minute;   
      
      this.to[0].hour = timing.monday.to.hour;
      this.to[0].minute = timing.monday.to.minute;
      this.to[1].hour = timing.tuesday.to.hour;
      this.to[1].minute = timing.tuesday.to.minute;
      this.to[2].hour = timing.wednesday.to.hour;
      this.to[2].minute = timing.wednesday.to.minute;
      this.to[3].hour = timing.thursday.to.hour;
      this.to[3].minute = timing.thursday.to.minute;
      this.to[4].hour = timing.friday.to.hour;
      this.to[4].minute = timing.friday.to.minute;

      this.disablePicker[0]=timing.monday.holiday;
      this.disablePicker[1]=timing.tuesday.holiday;
      this.disablePicker[2]=timing.wednesday.holiday;
      this.disablePicker[3]=timing.thursday.holiday;
      this.disablePicker[4]=timing.friday.holiday;   
    }
    //Set timings for Weekends
      this.satFrom.hour = timing.saturday.from.hour;
      this.satFrom.minute = timing.saturday.from.minute;
      this.satTo.hour = timing.saturday.to.hour;
      this.satTo.minute = timing.saturday.to.minute;
      this.disableSat = timing.saturday.holiday;
      this.sunFrom.hour = timing.sunday.from.hour;
      this.sunFrom.minute = timing.sunday.from.minute;
      this.sunTo.hour = timing.sunday.to.hour;
      this.sunTo.minute = timing.sunday.to.minute;
      this.disableSun = timing.sunday.holiday;      
  }
}
