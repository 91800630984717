import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private selectedSpace = new BehaviorSubject('');
  // private spaceTypeData = new BehaviorSubject({});
  private applicationId = new BehaviorSubject('');

  currentSpace = this.selectedSpace.asObservable();
  // spaceData = this.spaceTypeData.asObservable();
  appId = this.applicationId.asObservable();
  
  constructor() { }

  changeSpace(space: string) {
    this.selectedSpace.next(space)
  }

  // newSpaceData(spaceInfo : any)
  // {
  //   this.spaceTypeData.next(spaceInfo);
  // }
 
  setAppId(id : string)
  {
    this.applicationId.next(id);
  }

}
