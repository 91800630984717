import { Component } from '@angular/core';
import {enableProdMode} from '@angular/core';
import { LoaderService } from './shared/loader';

enableProdMode();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public showLoader = false;
  title = 'GoFloaters SpaceRocket';
  constructor(private loaderService: LoaderService) {}
  
  ngOnInit() {
    this.loaderService.status.subscribe((val: boolean) => {
      if (val) {
        this.showLoader = true;
      } else {
        this.showLoader = false;
      }
    });
  }

}
