import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { AddSpaceComponent } from './add-space/add-space.component';


const routes: Routes = [
  { path : '', component : HomeComponent },
  { path : 'menu', component : MenuComponent },
  { path : 'addspace', component : AddSpaceComponent },
  { path : 'edit/:appid', component : AddSpaceComponent },
  // { path:'addspace',component:BasicCheckScreenComponent,canDeactivate: [CanDeactivateGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
