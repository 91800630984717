import { Injectable } from "@angular/core";
import { Http, Response } from "@angular/http";
import { Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs/Observable";
import 'rxjs/Rx'; 
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { AppConstants } from "../app.constants";
import { AngularFireAuth } from "angularfire2/auth";
import { SharedProvider } from '../shared/shared';

@Injectable({
  providedIn:'root'
})
export class AddSpaceService { 
  url = "api/books";
  serviceUrl: any;
  baseurl: any;
  appurl: string;
  options: any;

  constructor(
    private http: Http,
    public _ANGFIRE: AngularFireAuth,
    public sharedService : SharedProvider
  ) {
    this.baseurl =
      AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].baseurl;
    this.appurl =
      AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].appurl;
    this.serviceUrl = AppConstants.CONSTANTS.spaceRocket;
    const token = window.localStorage.getItem("floaterstoken");
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    });
    this.options = new RequestOptions({ headers: headers });
  }

  // private extractData(res: Response) {
  //   const body = res.json();
  //   return body.data || {};
  // }
  // private handleErrorObservable(error: Response | any) {
  //   console.error(error.message || error);
  //   return Observable.throw(error.message || error);
  // }

  async getSpaceDataById(value: any) {
    // const token = await this._ANGFIRE.auth.currentUser.getIdToken();
    let headers = new Headers({
      "Content-Type": "application/json",
      // Authorization: "Bearer " + token
    });
    let options = new RequestOptions({ headers: headers });
    const url = this.baseurl + this.serviceUrl.getSpaceDataById;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value), options)
        .map(res => res.json())
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  async addSpace(value) {
    // const token = await this._ANGFIRE.auth.currentUser.getIdToken();
    let headers = new Headers({
      "Content-Type": "application/json",
      // Authorization: "Bearer " + token
    });
    let options = new RequestOptions({ headers: headers });
    const url = this.baseurl + this.serviceUrl.addSpace;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, value, options)
        .map(res => res.json())
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  async addOrRemoveSpacePhotos(value)
  {
    // const token = await this._ANGFIRE.auth.currentUser.getIdToken();
    let headers = new Headers({
      "Content-Type": "application/json",
      // Authorization: "Bearer " + token
    });
    let options = new RequestOptions({ headers: headers });
    const url = this.baseurl + this.serviceUrl.addphotos;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, value, options)
        .map(res => res.json())
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  async addOrRemoveSubSpace(value)
  {
    // const token = await this._ANGFIRE.auth.currentUser.getIdToken();
    let headers = new Headers({
      "Content-Type": "application/json",
      // Authorization: "Bearer " + token
    });
    let options = new RequestOptions({ headers: headers });
    const url = this.baseurl + this.serviceUrl.subSpace;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, value, options)
        .map(res => res.json())
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  async changeStatus(value) {
    // const token = await this._ANGFIRE.auth.currentUser.getIdToken();
    let headers = new Headers({
      "Content-Type": "application/json",
      // Authorization: "Bearer " + token
    });
    let options = new RequestOptions({ headers: headers });
    const url = this.baseurl + this.serviceUrl.changeStatus;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, value, options)
        .map(res => res.json())
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  async getMetaData()
  {
  const url = this.appurl + this.serviceUrl.getmetadata;
  return new Promise((resolve, reject) => {
    return this.http.get(url)
    .map(res => res.json())
    .subscribe((values:any) => {
          this.sharedService.setOperationalCities(values.operationalCities);
          resolve(values);
        },
        err => {
          reject(err);
        });
  });
}

}


