import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm} from '@angular/forms';
import { SharedProvider } from '../shared/shared';
import { AddSpaceService } from '../appservices/addspace.service';
import { LoaderService } from '../shared/loader';
import { FormCanDeactivate } from '../form-can-deactivate';

@Component({
  selector: 'app-people-info',
  templateUrl: './people-info.component.html',
  styleUrls: ['./people-info.component.scss']
})
export class PeopleInfoComponent extends FormCanDeactivate implements OnInit {
  peopleInfoStep : FormGroup;
  @Input() mode : string;
  @Input() show : boolean;
  @Input() appstatus : boolean;
  constructor(private formBuilder : FormBuilder, public shared : SharedProvider, public addspaceService : AddSpaceService,
    private loader: LoaderService) {
    super();
  }
  @ViewChild('form',{static:false})
  form: NgForm;
  cityInfo:any;
  ngOnInit() {
    if(this.shared.citiesMetaData)
      this.cityInfo = this.shared.getActiveCities();
    else
    {
      this.loader.display(true);
      this.addspaceService.getMetaData().then(val => {
      this.loader.display(false);
      this.cityInfo = this.shared.getActiveCities();
    }).catch(err => {
      this.loader.display(false);
      console.log(err);
    });
    }
    this.peopleInfoStep = this.formBuilder.group({
      ownerName: ['', Validators.required],
      ownerEmail: ['', Validators.email],
      ownerPhone: ['', [Validators.required,Validators.minLength(10),Validators.maxLength(10),Validators.pattern("[0-9]*")]],
      managerName: ['', Validators.required],
      managerEmail: ['', Validators.email],
      managerPhone: ['', [Validators.required,Validators.minLength(10),Validators.maxLength(10),Validators.pattern("[0-9]*")]],
      city:['', Validators.required],
      country:['India', Validators.required],
      pincode:['', Validators.required]
    });
    if(this.mode == 'edit')
    {
      let savedData = this.shared.getSpaceData();
      let data = savedData.spaceData.partnerDetails;
      if(data)
      {      
      this.peopleInfoStep.patchValue({
      ownerName: data.ownerName,
      ownerEmail: data.ownerEmail,
      ownerPhone: data.ownerPhone,
      managerName: data.managerName,
      managerEmail: data.managerEmail,
      managerPhone: data.managerPhone,
      city :data.city,
      country :data.country,
      pincode :data.pincode
      });  
      }
    }
    if(this.appstatus)
    {
      this.peopleInfoStep.disable();
    }

  }

  submitHandler()
  {
    let data = this.shared.getSpaceData();
    data.spaceData.partnerDetails = this.peopleInfoStep.value;
    // let proceedFlag = true;
    // if (data.spaceData.partnerDetails.ownerPhone == data.spaceData.partnerDetails.managerPhone) {
    //   alert("Owner phone and manager phone are the same. Kindly provide an alternative phone number");
    //   // this.peopleInfoStep.get("managerPhone").setErrors({ 'incorrect': true});
    //   this.peopleInfoStep.get("managerPhone").markAsDirty();
    //   proceedFlag = false;
    // }
    // if (data.spaceData.partnerDetails.managerEmail == data.spaceData.partnerDetails.ownerEmail) {
    //   alert("Owner email id and manager email id are the same. Kindly provide an alternative email id");
    //   // this.peopleInfoStep.get("managerEmail").setErrors({ 'incorrect': true});
    //   this.peopleInfoStep.get("managerEmail").markAsDirty();
    //   proceedFlag = false;
    // } 
    // if (proceedFlag) { 
    this.loader.display(true);
    return new Promise((resolve, reject) => {
      this.addspaceService.addSpace(data).then((res : any) => {
        this.loader.display(false);
        this.shared.setSpaceData(res);
        resolve(res);
       })
        .catch(err => {
          this.loader.display(false);
          if (err.status === 403) {
            console.log(err);
          }
        });
      })
    // }
  }
  
}
