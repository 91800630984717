import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { DataService } from '../data.service';
import { SharedProvider } from '../shared/shared';
import { AddSpaceService } from '../appservices/addspace.service';
import { LoaderService } from '../shared/loader';
import { Router } from '@angular/router';
import { FormCanDeactivate } from '../form-can-deactivate';

@Component({
  selector: 'app-amenities',
  templateUrl: './amenities.component.html',
  styleUrls: ['./amenities.component.scss']
})
export class AmenitiesComponent extends FormCanDeactivate implements OnInit {
  @Input() mode : string;
  @Input() appstatus : boolean;
  selectedSpace :string;
  amenitiesStep : FormGroup;
  cafeControls: any = {};
  meetingSpaceControls : any = {};
  sharedOfficeControls : any = {}; 
  constructor(private formBuilder : FormBuilder, private data: DataService, private shared : SharedProvider, 
    private addspaceService : AddSpaceService, private loader: LoaderService,private router : Router) {
    super();
  }
    @ViewChild('form',{static:false})
    form: NgForm;
  ngOnInit() {
    this.data.currentSpace.subscribe(space => this.selectedSpace = space );
    this.cafeControls = {
      pantry : [''], 
      teaCoffee : ['', Validators.required],
      food : ['', Validators.required],
      stationery : [''],
      printer : [''],
      copier : [''],    
    }
    this.meetingSpaceControls = {
      pantry : ['', Validators.required], 
      teaCoffee : ['', Validators.required],
      food : ['', Validators.required],
      stationery : [''],
      printer : [''],
      copier : [''], 
    }
    this.sharedOfficeControls = {
      pantry : ['', Validators.required], 
      teaCoffee : ['', Validators.required],
      food : ['', Validators.required],
      stationery : ['', Validators.required],
      printer : ['', Validators.required],
      copier : ['', Validators.required],    
    };

    if(this.selectedSpace == 'Cafe' || this.selectedSpace == 'Restaurant')
    {
      this.amenitiesStep = this.formBuilder.group(this.cafeControls);
    }
    else if(this.selectedSpace == 'MeetingSpace' || this.selectedSpace == 'EventVenue' || this.selectedSpace == 'TrainingRoom')
    {
      this.amenitiesStep = this.formBuilder.group(this.meetingSpaceControls);
    }
    else if(this.selectedSpace == 'SharedOffice' || this.selectedSpace == 'CoworkingSpace')
    {
      this.amenitiesStep = this.formBuilder.group(this.sharedOfficeControls);
    }
    if(this.mode == 'edit')
    {
      let savedData = this.shared.getSpaceData();
      let data = savedData.spaceData.amenityDetails;
      if(data)
      {
      if(savedData.type == 'Cafe' || savedData.type == 'Restaurant')
      {
        this.amenitiesStep.patchValue({
          pantry : data.pantry, 
          teaCoffee : data.teaCoffee,
          food : data.food,
          stationery : data.stationery,
          printer : data.printer,
          copier : data.copier, 
        });
      }
      else if(savedData.type == 'MeetingSpace' || savedData.type == 'EventVenue' || savedData.type == 'TrainingRoom')
      {
        this.amenitiesStep.patchValue({
          pantry : data.pantry, 
          teaCoffee : data.teaCoffee,
          food : data.food,
          stationery : data.stationery,
          printer : data.printer,
          copier : data.copier, 
        });
      }
      else if(savedData.type == 'SharedOffice' || savedData.type == 'CoworkingSpace')
      {
        this.amenitiesStep.patchValue({
          pantry : data.pantry, 
          teaCoffee : data.teaCoffee,
          food : data.food,
          stationery : data.stationery,
          printer : data.printer,
          copier : data.copier, 
        });
      }
      }
    }
    if(this.appstatus)
    {
      this.amenitiesStep.disable();
    }
   
  }


  submitHandler()
  {
    let data = this.shared.getSpaceData();
    data.spaceData.amenityDetails = this.amenitiesStep.value;  
    this.loader.display(true);
    return new Promise((resolve, reject) => {
      this.addspaceService.addSpace(data).then((res : any) => {
        this.loader.display(false);
        this.shared.setSpaceData(res);
        resolve(res);
       })
        .catch(err => {
          this.loader.display(false);
          if (err.status === 403) {
            console.log(err);
          }
        });
      })

  }

}
