import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog, MatStepper } from '@angular/material';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { DataService } from '../data.service';
import { SharedProvider } from '../shared/shared';
import { AddSpaceService } from '../appservices/addspace.service';
import { AngularFireStorage } from 'angularfire2/storage';
import { DragulaService } from 'ng2-dragula';
import { AppConstants } from "../app.constants";
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import * as _ from "lodash";
import * as firebase from 'firebase/app';
import { LoaderService } from '../shared/loader';
import { FormCanDeactivate } from '../form-can-deactivate';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-space-type',
  templateUrl: './space-type.component.html',
  styleUrls: ['./space-type.component.scss']
})
export class SpaceTypeComponent extends FormCanDeactivate implements OnInit {

  // @Output() spaceAdded = new EventEmitter<boolean>();
  spaceType : FormGroup;
  hide : boolean = true;
  spaceDetails : any = {};
  showPanel : Boolean = true;
  panelData : string[] = [];
  selectedSpace : string = '';
  // spaceAdded : boolean = false;
  @Input() mode : string;
  @Input() appstatus : boolean;
  downloadURLs: any = [];	
  fileStoragePath: any;
  alltaskList: any[] = [];
  allPercentage: Observable<number>[] = [];
  uploadPercentageList: any[] = [];
  space: any = { photos: [] };
  uploadPercent: any;
  storageUrl: any;
  isHovering: boolean;
  disableSave: boolean = true;
  chipData : any[] = [];
  private imageChangedEvent = null;
  croppedImage: string;
  imageCroppingDone: boolean = true;
  croppedImageSize: string = "";

  constructor( public dialog: MatDialog, private formBuilder : FormBuilder, private data: DataService, 
    private storage: AngularFireStorage,  private dragulaService: DragulaService,
  public shared : SharedProvider, public addspaceService : AddSpaceService, private loader: LoaderService) { 
	  super();
	this.storageUrl =
	AppConstants.CONSTANTS[
		AppConstants.CONSTANTS.currentEnvironment
	].firebase.storageBucket;
	this.fileStoragePath = AppConstants.CONSTANTS.filestorage.filepath;
    dragulaService.createGroup("space-photos", {
      revertOnSpill: true
    });
  }
  @ViewChild('form',{static:false})
  form: NgForm;
  ngOnInit() {
    // this.data.spaceData.subscribe(spaceInfo => this.spaceDetails = spaceInfo );
	this.data.currentSpace.subscribe( space => this.selectedSpace = space );
    this.spaceType = this.formBuilder.group(      
    {
      check : [null]
    },
    {
      validator : this.subSpaceAdded("check")
    });	
	if(this.mode == 'edit')
    {
	  let savedData = this.shared.getSpaceData();
	  let data;
	  if(savedData.type == 'MeetingSpace' || savedData.type == 'EventVenue' || savedData.type == 'SharedOffice' || savedData.type == 'TrainingRoom')
	  {
		data = savedData.spaceData.subSpaceDetails;
		if(data)
		{
			this.displayAccordion();
		}
		
	  }
	  else
	  {
		data = savedData.photos;
		if(data)
		{      
			this.space.photos = data;
	  	}
	  }
      
	}
	this.checkIfValid(this.selectedSpace);
	if(this.appstatus)
    {
      this.spaceType.disable();
    }
  }

  
  subSpaceAdded(ch : string)
  {
    return (group : FormGroup) => {
	  let check = group.controls[ch];
	  if(this.selectedSpace == 'MeetingSpace' || this.selectedSpace == 'EventVenue' || this.selectedSpace == 'SharedOffice' || this.selectedSpace == 'TrainingRoom')
	  {
		let savedData = this.shared.getSpaceData();
		if(savedData.spaceData)
		{
			let data = savedData.spaceData.subSpaceDetails;
			if(data)
			{ 
			  check.setErrors(null);            	          
			}
		  else
		  {
			  check.setErrors({
				  invalid:true
			})    
		  }
		}		
	  }
	  else
		{
			if(this.space.photos.length == 0)
			{
				check.setErrors({
					invalid : true
				});
			}
			else
			{
				check.setErrors(null);
			}
		}
  	} 
}



  openDialog(type : string, action : string, key:string ): void {
    const dialogRef = this.dialog.open(DialogComponent, {
       width: '700px',
      data: { message: type, action: action, key : key, appstatus:this.appstatus }
    });
    dialogRef.afterClosed().subscribe(result => { 
      this.displayAccordion();
    });
  }

  displayAccordion()
  {
	this.chipData = [];
	let temp = this.shared.getSpaceData();
	let tempdata = temp.spaceData.subSpaceDetails;
	for(var key in tempdata) {
		if(tempdata.hasOwnProperty(key)) {
			var value = tempdata[key];
			// console.log("value",value);
			this.chipData.push({"name":value.name,"photo":value.photos[0]});
		}		
	}
	if(this.chipData.length > 0)
	{
		this.hide = false;
	  	this.checkIfValid(this.selectedSpace);
	}
	else
	{
		this.chipData = [];
		this.checkIfValid(this.selectedSpace);
	}
  }


  select(name: any)
  {
	let type;
	let spacekey;
	let temp = this.shared.getSpaceData();
	let tempdata = temp.spaceData.subSpaceDetails;
	for(var key in tempdata) {
		if(tempdata.hasOwnProperty(key)) {
			if(tempdata[key].name == name)
			{
				type = tempdata[key].type;
				spacekey = key;
				break;
			}
		}		
	}
	this.openDialog(type,"update", spacekey);
  }


  remove(name: any): void {
	let searchkey;
	let temp = this.shared.getSpaceData();
	let tempdata = temp.spaceData.subSpaceDetails;
	for(var key in tempdata) {
		if(tempdata.hasOwnProperty(key)) {
			if(tempdata[key].name == name)
			{
				searchkey = key;
				break;
			}
		}		
	}
	let d = this.shared.getSpaceData();
	const space = {
		applicationId : d.applicationId,
		applicationKey : d.applicationKey,
		action: "remove",
		subSpaceKey:searchkey
	};
	this.loader.display(true);
	this.addspaceService
		.addOrRemoveSubSpace(space)
		.then((spacevalue: any) => {
			this.loader.display(false);
			this.shared.setSpaceData(spacevalue);
			this.displayAccordion();
		})
		.catch(err => {
			console.log(err);
			this.loader.display(false);
		});
}

  submitHandler()
  {
	if(this.selectedSpace == 'MeetingSpace' || this.selectedSpace == 'EventVenue' || this.selectedSpace == 'SharedOffice' || this.selectedSpace == 'TrainingRoom')
	{
		// let data = this.shared.getSpaceData();
		// return new Promise((resolve, reject) => {
		//   this.addspaceService.addSpace(data).then((res : any) => {
		// 	this.shared.setSpaceData(res);
		// 	resolve(res);
		//    })
		// 	.catch(err => {
		// 	  if (err.status === 403) {
		// 		console.log(err);
		// 	  }
		// 	});
		//   })
	}
	else
	{
		this.createOrAddPhotos("add", this.space.photos);
	}   
  }

  checkIfValid(spaceType)
  {
	let check = this.spaceType.get('check');
	if(spaceType != 'MeetingSpace' && spaceType != 'EventVenue' && spaceType != 'SharedOffice' && spaceType != 'TrainingRoom')
	{
		if(this.space.photos.length > 0)
		{
		   this.disableSave = false;
		   check.setErrors(null);    
		}
		else
		{
			this.disableSave = true;
			check.setErrors({
				invalid : true
			});
		}
	}
	else
	{
		let savedData = this.shared.getSpaceData();
		if(savedData.spaceData)
		{
			let data = savedData.spaceData.subSpaceDetails;
			if(data)
			{ 
			  this.disableSave = false;   
			  check.setErrors(null);            	          
			}
		  else
		  {
			  this.disableSave = true;   
			  check.setErrors({
				  invalid:true
			})    
		  }
		}		
	}
  }
  


	toggleHover(event: boolean) {
		this.isHovering = event;
	}


	uploadFileForCropping(event) {
		// console.log("Image for cropping",event);
		// if (!this.disableFileUpload()) {
			const filelist = event;
			if (filelist.length > 1) {
				console.error("Select only one file at a time");
				alert("Upload only one file at a time.")
				return;
			}
			this.imageChangedEvent = event;
			this.imageCroppingDone = false;
		// }
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
		this.croppedImageSize = event.width + " px X " + event.height + " px"; 
	}
	async doneCropping () {
		// console.log("this.croppedImage",this.croppedImage);
		// this.croppedImage = null;
		await this.uploadFileBase64();
		this.imageCroppingDone = true;
		this.imageChangedEvent = null;
	}
	imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
	}

	uploadFileBase64() {
		const filename = `${this.space.spaceName}_${new Date().getTime()}`;
		const filePath = this.fileStoragePath + "/" + filename;
		const customMetadata = { app: "Space Admin App", name: filename,contentType:'image/jpg' };
		const fileRef = this.storage.ref(filePath);
		const task = fileRef.putString(this.croppedImage.split(',')[1],'base64',customMetadata);
		const _percentage$ = task.percentageChanges();
		this.alltaskList.push(task);
		this.allPercentage.push(_percentage$);
		task.then(ress => {
			ress.ref.getDownloadURL().then(val => {
				let link = val;
				var index = _.findIndex(this.uploadPercentageList, {
					filename: this.getfilename(link)
				});
				if (index >= 0) {
					this.uploadPercentageList.splice(index, 1);
				}
				this.downloadURLs = this.downloadURLs.concat([link]);
				// this.allPercentage.forEach(val => {
				// })
				if (this.space && this.space.photos && this.space.photos.length > 0) {
					// this.space.photos = this.space.photos.push(url);
					this.space.photos = _.union(this.space.photos, this.downloadURLs);
					this.createOrAddPhotos("add", this.space);
				} else {
					// const updatedImage = [...this.downloadURLs];
					this.space.photos = [...this.downloadURLs];
					if (this.space.spaceId) {
						this.createOrAddPhotos("add", this.space);
					} else {
						this.createOrAddPhotos("create", this.space);
					}
				}
			});
		});
		// observe percentage changes
		this.uploadPercent = task.percentageChanges();
		this.alltaskList.push(task);
		// get notified when the download URL is available
		const ts = task
			.snapshotChanges()
			.pipe(
				tap(snap => {
					// console.log("snap",snap);
					var index = _.findIndex(this.uploadPercentageList, {
						filename: snap.metadata.name
					});
					// let avl_list = this.uploadPercentageList.find(percent => percent.filename === snap.metadata.customMetadata.name)
					if (index >= 0) {
						const updatedpercentage = {
							percentage: (snap.bytesTransferred / snap.totalBytes) * 100,
							state: snap.state,
							filename: snap.metadata.name
						};
						this.uploadPercentageList.splice(index, 1, updatedpercentage);
					} else {
						const uploadPercentage = {
							percentage: (snap.bytesTransferred / snap.totalBytes) * 100,
							state: snap.state,
							filename: snap.metadata.name
						};
						this.uploadPercentageList.push(uploadPercentage);
					}
					if (snap.bytesTransferred === snap.totalBytes) {
					}
				})
			)
			.subscribe();
	}


  uploadFile(event) {
		// if (!this.disbaleFileUpload()) {
			this.downloadURLs = [];
			const filelist = event;

			for (const file of filelist) {
				if (file.size > 5242880) {
					console.error("File size is greater than 5 MB ");
					return;
				}

				if (file.type.split("/")[0] !== "image") {
					console.error("unsupported file type :( ");
					return;
				}
				
				const filename = `${new Date().getTime()}_${file.name}`;
				const filePath = this.fileStoragePath + "/" + filename;
				const customMetadata = { app: "Space Rocket App", name: filename };				
				const task = this.storage.upload(filePath, file, { customMetadata });
				const fileRef = this.storage.ref(filePath);
				const _percentage$ = task.percentageChanges();
				this.alltaskList.push(task);
				this.allPercentage.push(_percentage$);	
				task.then( ress => {
					this.getURL(ress);					
				});
			
				// observe percentage changes
				this.uploadPercent = task.percentageChanges();
				this.alltaskList.push(task);
				// get notified when the download URL is available
				const ts = task
					.snapshotChanges()
					.pipe(
						tap(snap => {
							var index = _.findIndex(this.uploadPercentageList, {
								filename: snap.metadata.customMetadata.name
							});
							// let avl_list = this.uploadPercentageList.find(percent => percent.filename === snap.metadata.customMetadata.name)
							if (index >= 0) {
								const updatedpercentage = {
									percentage: (snap.bytesTransferred / snap.totalBytes) * 100,
									state: snap.state,
									filename: snap.metadata.customMetadata.name
								};
								this.uploadPercentageList.splice(index, 1, updatedpercentage);
							} else {
								const uploadPercentage = {
									percentage: (snap.bytesTransferred / snap.totalBytes) * 100,
									state: snap.state,
									filename: snap.metadata.customMetadata.name
								};
								this.uploadPercentageList.push(uploadPercentage);
							}
							if (snap.bytesTransferred === snap.totalBytes) {					
							}
						})
					)
					.subscribe();
			}
		// }
	}

	getURL(ress) {
		let link;
		ress.ref.getDownloadURL().then(l => {
			link = l;
			var index = _.findIndex(this.uploadPercentageList, {
				filename: this.getfilename(link)
			});
			if (index >= 0) {
				this.uploadPercentageList.splice(index, 1);
			}
			this.downloadURLs = this.downloadURLs.concat([link]);
			// this.allPercentage.forEach(val => {
			// })
			if (this.space.photos && this.space.photos.length > 0) {
				// this.space.photos = this.space.photos.push(url);
				this.space.photos = _.union(this.space.photos, this.downloadURLs);
				this.createOrAddPhotos("add", this.space.photos);
			} else {
				// const updatedImage = [...this.downloadURLs];
				this.space.photos = [...this.downloadURLs];
				// if (this.space.spaceId) {
				this.createOrAddPhotos("add", this.space.photos);
				// } 
			}
		});
		// return link;
	}
	getfilename(fullpath) {
		let storagepath = decodeURIComponent(fullpath).split(
			this.storageUrl + "/o/" + this.fileStoragePath
		)[1];
		return storagepath.substring(1, storagepath.indexOf("?"));
	}
	createOrAddPhotos(action, spacephotos) {	
		let d = this.shared.getSpaceData();
		const spaceval = {
			applicationId : d.applicationId,
			applicationKey : d.applicationKey,
			action: action,
			photos: spacephotos,
			app: "SpaceRocketApp"
		};

		this.addspaceService
			.addOrRemoveSpacePhotos(spaceval)
			.then((spacevalue: any) => {
				console.log("Space photos updated");
				let newData = this.shared.getSpaceData();
				newData.photos = spacevalue["photos"];
				this.shared.setSpaceData(newData);
				this.checkIfValid(this.selectedSpace);
				// this.space.spaceId = spacevalue.spaceId;
			});
			
	}

	deleteImage(name) {
		this.loader.display(true);
		if (this.space.photos.length === 1) {
			/*
				If there is only one photo we just remove it only from dom.
				If the user try to save the changes we will show the error
				pop up
			*/
			this.space.photos = [];
			this.loader.display(false);
			this.checkIfValid(this.selectedSpace);
			return ;
		}
		const updatedphotos = [...this.space.photos];
		updatedphotos.splice(updatedphotos.indexOf(name), 1);
		let d = this.shared.getSpaceData();
		const spaceval = {
			applicationId : d.applicationId,
			applicationKey : d.applicationKey,
			action: "remove",
			photos: updatedphotos,
			app: "SpaceRocketApp"
		};

		this.addspaceService.addOrRemoveSpacePhotos(spaceval).then(spacevalue => {
			this.space.photos = [...updatedphotos];
			let newData = this.shared.getSpaceData();
			newData.photos = spacevalue["photos"];
			this.shared.setSpaceData(newData);
			this.checkIfValid(this.selectedSpace);
			let storagepath = name.split(this.storageUrl + "/o/")[1];
			const path = decodeURIComponent(
				storagepath.substring(0, storagepath.indexOf("?"))
			);
			const storageRef = firebase.storage().ref();
			storageRef
				.child(path)
				.delete()
				.then(val => {
					console.log("Image delete success");					
					this.loader.display(false);
				})
				.catch(err => {
					console.log("Image delete failed");
					this.loader.display(false);
				});
		});
		

	}

	ngOnDestroy() {
		this.dragulaService.destroy("space-photos");
	}


}
