import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { DataService } from '../data.service';
import { SharedProvider } from '../shared/shared';
import { AddSpaceService } from '../appservices/addspace.service';
import { LoaderService } from '../shared/loader';
import { FormCanDeactivate } from '../form-can-deactivate';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent extends FormCanDeactivate implements OnInit {
  @Input() mode : string;
  @Input() appstatus : boolean;
  companyInfoStep : FormGroup;
  cityInfo:any;
  constructor(private formBuilder : FormBuilder,private data: DataService, private shared : SharedProvider,
     private addspaceService : AddSpaceService, private loader: LoaderService) {
    super();
  }

     @ViewChild('form',{static:false})
     form: NgForm;

  ngOnInit() {
    if(this.shared.citiesMetaData)
      this.cityInfo = this.shared.getActiveCities();
    else
    {
      this.loader.display(true);
      this.addspaceService.getMetaData().then(val => {
      this.loader.display(false);
      this.cityInfo = this.shared.getActiveCities();
    }).catch(err => {
      this.loader.display(false);
      console.log(err);
    });
    }
  this.companyInfoStep = this.formBuilder.group({
    gstCheck: [''],
    gstin: [''],
    companyName : [''],
    street : [''],
    area : [''],
    city: [''],
    country : ['India'],
    pincode : [''],
    bankName: [''],
    bankAccNo : [''],
    ifsc: [''],
  });
  if(this.mode == 'edit')
  {
    let savedData = this.shared.getSpaceData();
    let data = savedData.spaceData.companyDetails;
      if(data)
      { 
      this.companyInfoStep.patchValue({
        gstCheck: data.gstCheck,
        gstin: data.gstin,
        companyName : data.companyName,
        street : data.street,
        area : data.area,
        city: data.city,
        country : data.country,
        pincode : data.pincode,
        bankName: data.bankName,
        bankAccNo:data.bankAccNo,
        ifsc: data.ifsc
      });  
    }
  }
  if(this.appstatus)
    {
      this.companyInfoStep.disable();
    }

  }
  gstChange(e)
  {
    if(e.value == 'No')
    {
      this.companyInfoStep.controls['gstin'].setValue('N/A');
    }
    else
    {
      this.companyInfoStep.controls['gstin'].setValue('');
    }
  }

  submitHandler()
  {
    let data = this.shared.getSpaceData();
    data.spaceData.companyDetails = this.companyInfoStep.value;  
    this.loader.display(true);
    return new Promise((resolve, reject) => {
      this.addspaceService.addSpace(data).then((res : any) => {
        this.loader.display(false);
        this.shared.setSpaceData(res);
        resolve(res);
       })
        .catch(err => {
          this.loader.display(false);
          if (err.status === 403) {
            console.log(err);
          }
        });
      })

  }

}
